import React from "react";
import { Link } from "gatsby";
import { useDispatch } from 'react-redux';
import { logoutLaunched } from '../../state/reducer';

const SwitchInfoOrder = ({ lang, currentPath }) => {
  const dispatch = useDispatch()
  const logout = () => {
    dispatch(logoutLaunched());
  };
  return (
    <div className="bg-orange flex-col mt-4 mx-2 sm:mx-4 md:mx-10 py-4 space-y-4 md:content-center">
      <div className="h-1/2 flex items-center justify-between pl-8">
        <Link to={`/${lang}/account`}>
          <p className={`flex font-bold text-xs sm:text-s md:text-sm text-black ${currentPath === `/${lang}/account` ? 'underline' : ''}`}>
            Mes informations

          </p>
        </Link>
        <svg class="h-6 md:h-8 w-6 md:w-8 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <polyline points="9 18 15 12 9 6" />
        </svg>
      </div>
      <div className="h-1/2 flex items-center justify-between pl-8">
        <Link to={`/${lang}/order`}>
          <p className={`flex font-bold text-xs sm:text-s md:text-sm text-black ${currentPath === `/${lang}/order` ? 'underline' : ''}`}>
            Mes commandes
          </p>
        </Link>
        <svg class="h-6 md:h-8 w-6 md:w-8 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <polyline points="9 18 15 12 9 6" />
        </svg>
      </div>
      <div className="h-1/2 flex items-center justify-between pl-8">
        <button
          onClick={() => logout()}
          className="font-bold text-xs sm:text-s md:text-sm text-black"
        >
          Déconnexion
        </button>
        <svg class="h-6 md:h-8 w-6 md:w-8 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <polyline points="9 18 15 12 9 6" />
        </svg>
      </div>
    </div>
  );
};

export default SwitchInfoOrder;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from "../../../i18n";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CustomLoader from '../../components/CustomLoader';
import CoverImage from '../../components/CoverImage';
import signUpMobile from '../../../assets/signupMobile.png';
import signUpDesktop from '../../../assets/signUpDesktop.png';
import NavBarMenu from '../../components/NavBarMenu';
import OrderInfo from '../../components/OrderInfo';
import SwitchInfoOrder from '../../components/SwitchInfoOrder';
import { navigate } from 'gatsby';
import ModalContact from '../../components/ModalContact';
import { searchCustomerOrdersLaunched } from '../../state/reducer';
import { dateComparison } from '../../utils/format';
import generateMeta from '../../utils/meta';

const MyOrderPage = ({ data, pageContext }) => {
  // SEO
  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}${pageContext.currentPath}`,
  }];

  const metas = generateMeta(initMetas, data?.markdownRemark?.frontmatter);

  const lang = pageContext.locale;
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  const dispatch = useDispatch();

  const { isAuthenticated, isAuthenticating, userDynamo, allCustomerOrders, searchCustomerOrdersLoading } = useSelector((state) => ({
    isAuthenticated: state.getIn(['root', 'isAuthenticated']),
    isAuthenticating: state.getIn(['root', 'isAuthenticating']),
    userDynamo: state.getIn(['root', 'userDynamo']),
    allCustomerOrders: state.getIn(['root', 'allCustomerOrders']),
    searchCustomerOrdersLoading: state.getIn(['root', 'searchCustomerOrdersLoading']),
  }));

  // check if client is connected
  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      navigate(`/${lang}/login`)
    } else return;
  }, [isAuthenticating]);

  // Modale Contact
  const [openModalContact, setOpenModalContact] = useState(false);
  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }
  const showModal = () => {
    setOpenModalContact(true);
    if (typeof window !== "undefined") {
      body.style.overflow = "hidden"; // block scroll on body when modal is open
    }
  };
  const hideModal = () => {
    setOpenModalContact(false);
    if (typeof window !== "undefined") {
      body.style.overflow = "auto";
    }
  };

  // get customer Orders when user is know
  useEffect(() => {
    if (isAuthenticated && userDynamo) {
      dispatch(searchCustomerOrdersLaunched());
    }
  }, [isAuthenticated, userDynamo])

  // prepare customerOrder data
  const [customerOrderData, setCustomerOrderData] = useState([]);
  useEffect(() => {
    if (!searchCustomerOrdersLoading && allCustomerOrders) {
      allCustomerOrders.forEach(v => { v.isOpen = false; }); // by default every customer order is closed
      allCustomerOrders.sort(dateComparison);
      setCustomerOrderData(allCustomerOrders);
    }
  }, [searchCustomerOrdersLoading, allCustomerOrders])

  // function when user open customerDetails
  const handleClick = (id) => {
    setCustomerOrderData(
      customerOrderData.map((d) =>
        d.id === id ? { ...d, isOpen: !d.isOpen } : { ...d, isOpen: false }
      )
    );
  };

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.currentPath}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>Mes commandes</title>
        {/* Seo and OpenGraph */}
        {metas && metas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      {(!userDynamo && isAuthenticating) ? <CustomLoader /> : null}
      {isAuthenticated && (
        <>
          <CoverImage
            imageDesktop={signUpDesktop}
            imageMobile={signUpMobile}
            title="Mes Commandes"
            altDesktop="palm trees"
            altMobile="palm trees"
          />
          <div className="max-w-screen-2xl mx-auto">
            <div className='lg:flex'>
              <div className='lg:w-1/4 w-full'>
                <SwitchInfoOrder lang={pageContext.locale} currentPath={pageContext.currentPath} />
              </div>
              <div className='lg:w-3/4 w-full flex-col justify-center'>
                <h2 className='text-lg font-semibold py-5 pl-10 mt-10'> Mes <strong className='text-orange-dark'> commandes </strong></h2>
                <div className="px-2 sm:px-6">
                  {customerOrderData.length !== 0 && customerOrderData.map((order) => (
                    <OrderInfo key={order.orderNumber} order={order} handleClick={handleClick} lang={pageContext.locale} />
                  ))}
                  {(!searchCustomerOrdersLoading && allCustomerOrders && customerOrderData.length === 0) && (
                    <>
                      <div className="flex justify-center h-52">
                        <p className="py-10 px-2">Vous retrouverez ici le détail de vos commandes.</p>
                      </div>
                    </>
                  )}
                  {(searchCustomerOrdersLoading && !allCustomerOrders) && (
                    <>
                      <div class="w-full animate-pulse px-4 text-left py-6 space-y-2">
                        <div class="flex items-center justify-between">
                          <p class="bg-grey-light h-4 w-36 rounded-md"></p>
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                          <div class="sm:flex">
                            <p class="bg-grey-light h-4 w-52 rounded-md">
                            </p>
                          </div>
                          <div class="mt-2 flex items-center justify-end sm:mt-0 bg-grey-light h-4 w-20 rounded-md">
                          </div>
                        </div>
                      </div>
                      <div class="w-full animate-pulse px-4 text-left py-6">
                        <div class="flex items-center justify-between">
                          <p class="bg-grey-light h-4 w-36 rounded-md"></p>
                        </div>
                        <div class="mt-2 sm:flex sm:justify-between">
                          <div class="sm:flex">
                            <p class="bg-grey-light h-4 w-52 rounded-md">
                            </p>
                          </div>
                          <div class="mt-2 flex items-center justify-end sm:mt-0 bg-grey-light h-4 w-20 rounded-md">
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {
              (userDynamo?.user.firstName && userDynamo?.user.lastName) && (
                <>
                  <div className='text-center py-5'>
                    <p
                      className="self-center mt-6 font-normal text-xxms"
                    >
                      Vous rencontrez un problème ou avez une question ?
                    </p>
                    <button
                      type="button"
                      className="font-medium text-xxms mb-4 md:mb-8 focus:outline-none focus:ring-0"
                      onClick={showModal}
                    >
                      Contacter Zawema
                    </button>
                  </div>
                  <ModalContact showModal={openModalContact} hideModal={hideModal} />
                </>
              )
            }
          </div>

        </>
      )}


    </Layout >
  )
}

export default MyOrderPage;

export const query = graphql`
    query( $locale: String! ) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                } 
              }
            }
        }
        markdownRemark(fileAbsolutePath: {regex: "/orders.md/"}) {
          html
          frontmatter {
              title
              description
              ogtitle
              ogdescription
          }
        }
    }
`
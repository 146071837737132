import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from "react-styles-hook";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { capitalizeFirst } from '../../utils/format';
import getStyles from "./styles";
import { clientContactLaunched } from '../../state/reducer';

export const ModalContact = ({ showModal, hideModal }) => {

  const classes = useStyles(getStyles());

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  // dropdown list
  const subjectList = [
    "Mon Compte",
    "Mes Commandes",
    "La Marketplace",
    "Autre"
  ];

  const [selected, setSelected] = useState(subjectList[0]);

  const [send, setSend] = useState(false);

  const dispatch = useDispatch();
  const { contactLoading, contactSuccess, contactError } = useSelector((state) => (
    {
      contactLoading: state.getIn(['root', 'clientContactLoading']),
      contactSuccess: state.getIn(['root', 'clientContactSuccessState']),
      contactError: state.getIn(['root', 'clientContactError']),
    }
  ));

  // auto close modal or delete confirmation message after 3s ONLY if form is submitted
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    const timer = setTimeout(() => hideModal(), 3000);
    return () => { clearTimeout(timer); };
  }, [contactSuccess]);

  // Form
  const [formValue, setFormValue] = useState({
    subject: selected,
    body: '',
  });
  const initialValues = {
    subject: formValue.subject,
    body: formValue.body,
  };


  const onSubmit = () => {
    dispatch(clientContactLaunched({ formValue }));
    setSend(true);
  };

  const validationSchema = Yup.object().shape({
    body: Yup.string()
      .min(10, capitalizeFirst("Nombre de caractères minimum : 10"))
      .required(capitalizeFirst("Veuillez préciser en quelques mots votre problème"))
  });

  return (
    showModal
    && (
      <div
        role="button"
        className="cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
        onKeyPress={hideModal}
        tabIndex="0"
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          // don't close modal if anything inside modal content is clicked or any key is pressed
          onClick={(e) => { e.stopPropagation(); }}
          onKeyPress={(e) => { e.stopPropagation(); }}
        >
          {
            (!send)
            && (
              <div className="w-3/4 md:w-full my-2">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ errors, touched, values, handleChange }) => (
                    <Form>
                      <div>
                        <Listbox
                          id="subject"
                          name="subject"
                          value={values.subject}
                          onChange={(e) => {
                            setSelected(e);
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              subject: e
                            }));
                          }}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-xxms font-medium text-gray-700 capitalize">Sujet</Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button className={`relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default ${(subjectList.length > 1) ? "focus:outline-none focus:ring-1 focus:ring-orange-dark focus:border-orange-dark" : ""} `}>
                                  <span className="block truncate text-xs">{capitalizeFirst(selected)}</span>
                                  {(subjectList.length > 1)
                                    && (
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </span>
                                    )}
                                </Listbox.Button>
                                {
                                  (subjectList.length > 1)
                                  && (
                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {subjectList.map((subject) => (
                                          <Listbox.Option
                                            key={subject}
                                            className={({ active }) => classNames(
                                              active ? 'text-white bg-orange-chocolate' : 'text-gray-900',
                                              'text-xs cursor-default select-none relative py-2 pl-8 pr-4'
                                            )}
                                            value={subject}
                                          >
                                            {({ newSelected, active }) => (
                                              <>
                                                <span className={classNames(newSelected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                  {capitalizeFirst(subject)}
                                                </span>

                                                {newSelected ? (
                                                  <span
                                                    className={classNames(
                                                      active ? 'text-white' : 'text-indigo-600',
                                                      'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                    )}
                                                  >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  )
                                }
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="body" className="block text-xxms font-medium text-gray-700">
                          Message
                        </label>
                        <div className="mt-1">
                          <Field
                            id="body"
                            name="body"
                            component="textarea"
                            rows={4}
                            value={values.body}
                            placeholder="Message"
                            onChange={(e) => {
                              handleChange(e);
                              setFormValue((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value
                              }));
                            }}
                            className="py-3 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-gray-300 rounded-md"
                          />
                          {(errors.body || touched.body) ? (
                            <p className="px-2 text-red text-center md:text-left">{errors.body}</p>
                          ) : null}
                        </div>
                        <div className="mt-6 flex justify-center">
                          <button
                            type="submit"
                            className="w-full md:w-52 px-3 py-1.5 border border-transparent rounded-md shadow-sm text-xxms font-medium text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dark"
                          >
                            {capitalizeFirst("Envoyer")}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="mt-2 flex justify-center">
                  {(hideModal)
                    && (
                      <button
                        type="button"
                        onClick={() => hideModal()}
                        className="w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-grey bg-white hover:bg-grey-dark hover:text-white focus:outline-none focus:ring-0 focus:ring-offset-0"
                      >
                        Annuler
                      </button>
                    )}
                </div>
              </div>
            )
          }
          {
            (send && contactLoading)
            && (
              <div className="text-center">
                {capitalizeFirst("Envoi en cours")}
              </div>
            )
          }
          {
            (send && contactSuccess)
            && (
              <div className="text-center">
                {capitalizeFirst("Votre message a bien été envoyé")}
              </div>
            )
          }
          {
            (send && contactError)
            && (
              <div className="text-center">
                {capitalizeFirst("une erreur est survenue, veuillez réessayer plus tard.")}
              </div>
            )
          }
        </div>
      </div>
    )
  );
};

export default ModalContact;
import React, { Fragment, useState, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import ModalIncident from '../../ModalIncident';
import IncidentDetail from '../IncidentDetails';


const SellerOrderDetails = ({ sellerOrder, userId, orderNumber, delivery, relayPoint, billing, lang }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  // Modale Declaration Incident
  const [openModalIncident, setOpenModalIncident] = useState(false);
  const showModal = () => {
    setOpenModalIncident(true);
  };
  const hideModal = () => {
    setOpenModalIncident(false);
  };

  // Modale Details incident
  const [openDetailIncident, setOpenDetailIncident] = useState(false);
  const showIncident = () => {
    setOpenDetailIncident(true);
  };
  const hideIncident = () => {
    setOpenDetailIncident(false);
  };

  let body;
  if (typeof window !== "undefined") { // during build window is unavailable
    body = document.querySelector("body");
  }
  useEffect(() => {
    if ((openModalIncident || openDetailIncident) && typeof window !== "undefined") {
      body.style.overflow = "hidden"; // block scroll on body when menu is open
    };
    if ((!openModalIncident && !openDetailIncident) && typeof window !== "undefined") {
      body.style.overflow = "auto"; // block scroll on body when menu is open
    }
  }, [openModalIncident, openDetailIncident,]);

  const translateStatut = (status) => {
    switch (status) {
      case "WAITING_ACCEPTANCE":
        return "En attente d'acceptation";
      case "WAITING_PREPARATION":
        return "En cours de préparation";
      case "WAITING_DELIVERY":
        return "En cours de livraison";
      case "DELIVERED":
        return "Livrée";
      case "CLOSED":
        return "Cloturée";
      case "REFUSED":
        return "Annulée par le vendeur";
      case "REFUNDED":
        return "Remboursée";
      default:
        return "Aucun statut disponible";
    }
  };

  // calcul total price for each SellerOrder
  const [totalPrice, setTotalPrice] = useState(0);
  if (sellerOrder) {
    let sumPrice = 0;
    sellerOrder.orderLines.map((orderLine) => {
      sumPrice += Number(orderLine.totalPrice);
    });
    setTotalPrice(sumPrice);
  }

  return (
    <>
      <div class="sm:pl-3 py-6 w-full">
        <h3 class="text-s text-grey-darkest">Vendu et expédié par <span className="text-orange-dark font-medium">{sellerOrder.sellerName ? sellerOrder.sellerName : "LoremIpsum"}</span></h3>
        <p className="text xs sm:text-s py-2 space-x-1"><span>Statut :</span><span>{translateStatut(sellerOrder.orderStatus)}</span></p>
        {/* Delivery and billing address section */}
        <div className="md:pl-4 flex flex-col md:flex-row md:justify-between py-4 space-y-6 md:space-y-0">
          <div class="w-full px-2 bg-white sm:px-6">
            <p class="text-xs">Adresse de livraison</p>
            {(sellerOrder.delivery.deliveryModeId === "relais-colis" && relayPoint.id !== null) && (
              <>
                <p className="text-grey-dark text-xxm sm:text-xs"><span>{delivery.firstName}</span><span class="pl-1.5">{delivery.lastName}</span></p>
                <p className="text-grey-dark text-xxm sm:text-xs">{relayPoint.name}</p>
                <p className="text-grey-dark text-xxm sm:text-xs">{relayPoint.zipCodeCity}</p>
                <p className="text-grey-dark text-xxm sm:text-xs">{relayPoint.country}</p>
              </>
            )}
            {(delivery && sellerOrder.delivery.deliveryModeId !== "relais-colis") && (
              <>
                <p className="text-grey-dark text-xxm sm:text-xs"><span>{delivery.firstName}</span><span class="pl-1.5">{delivery.lastName}</span></p>
                {delivery.company !== "" ? <p className="text-grey-dark text-xxm sm:text-xs">{delivery.company}</p> : null}
                <p className="text-grey-dark text-xxm sm:text-xs">{delivery.address}</p>
                <p className="text-grey-dark text-xxm sm:text-xs"><span>{delivery.zipCode}</span><span class="pl-1.5">{delivery.city}</span></p>
                <p className="text-grey-dark text-xxm sm:text-xs">{delivery.country}</p>
              </>
            )}
          </div>
          <div class="w-full px-2 bg-white sm:px-6">
            <p class="text-xs">Adresse de facturation</p>
            <p className="text-grey-dark text-xxm sm:text-xs"><span>{billing.firstName}</span><span class="pl-1.5">{billing.lastName}</span></p>
            {billing.company !== "" ? <p className="text-grey-dark text-xxm sm:text-xs">{billing.company}</p> : null}
            <p className="text-grey-dark text-xxm sm:text-xs">{billing.address}</p>
            <p className="text-grey-dark text-xxm sm:text-xs"><span>{billing.zipCode}</span><span class="pl-1.5">{billing.city}</span></p>
            <p className="text-grey-dark text-xxm sm:text-xs">{billing.country}</p>
          </div>
        </div>
        {/* Order Details */}
        <div class="md:pl-4 w-full flex flex-col">
          <div class="w-full sm:p-3 space-y-4">
            <table className="table-fixed text-left w-full">
              <thead className='p-2 border-b border-slate-300'>
                <tr className='py-2 md:px-3 text-grey-lightDark'>
                  <th className='p-2 w-6/12 text-xxm sm:text-xs'>Produit</th>
                  <th className='p-2 w-2/12 text-xxm sm:text-xs'>Qte</th>
                  <th className='p-2 w-3/12 text-xxm sm:text-xs'>Total</th>
                  <th className='p-2 w-1/12 text-xxm sm:text-xs'></th>
                </tr>
              </thead>
              <tbody className='py-2 md:px-3 h-24'>
                {
                  sellerOrder.orderLines.map((orderLine) => {
                    // display product by seller
                    return (
                      <>
                        <tr key={orderLine.offerId}>
                          <td className='md:px-3 md:py-2 text-xxm sm:text-xs space-x-2'>
                            <span className='row-span-2 whitespace-normal'>{JSON.parse(orderLine.product) ? JSON.parse(orderLine.product).fr : "Nom article"}</span>
                            {(orderLine.incident[0].hasIncident && orderLine.incident[0].status === "NEW") && (
                              <span className="px-2 py-0.5 text-white bg-red rounded-full">Incident en cours</span>
                            )}
                            {(orderLine.incident[0].hasIncident && (orderLine.incident[0].status === "REFUNDED" || orderLine.incident[0].status === "CLOSED")) && (
                              <span className="px-2 py-0.5 text-white bg-green rounded-full">Incident cloturé</span>
                            )}
                          </td>
                          <td className='md:px-3 md:py-2 text-xxm sm:text-xs'><span className='p-2 px-7'> {orderLine.quantity} </span></td>
                          <td className='md:px-3 md:py-2 text-orange-crossed text-xxm sm:text-xs'> {((orderLine.totalPrice) * 100 / 100).toFixed(2)} €</td>
                          <td className='md:px-3 md:py-2 text-xxm sm:text-xs'>
                            {(sellerOrder.orderStatus !== "WAITING_ACCEPTANCE" && sellerOrder.orderStatus !== "CLOSED" && sellerOrder.orderStatus !== "REFUSED") && (
                              <Menu as="div" className="relative inline-block text-left">
                                <div>
                                  <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                    <span className="sr-only">Open options</span>
                                    <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>
                                {(!openModalIncident && !openDetailIncident) && (
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                      <div className="py-1">
                                        <Menu.Item>
                                          {({ active }) => (
                                            <>
                                              {(!orderLine.incident[0].hasIncident) && (
                                                <button
                                                  className={classNames(
                                                    active ? 'text-grey-darkest' : 'text-grey-dark',
                                                    'block px-4 py-2 text-xs sm:text-s'
                                                  )}
                                                  onClick={() => showModal()}
                                                >
                                                  Déclarer un incident
                                                </button>
                                              )}
                                              {(orderLine.incident[0].hasIncident) && (
                                                <button
                                                  className={classNames(
                                                    active ? 'text-grey-darkest' : 'text-grey-dark',
                                                    'block px-4 py-2 text-xs sm:text-s'
                                                  )}
                                                  onClick={() => showIncident()}
                                                >
                                                  Voir l'incident
                                                </button>
                                              )}
                                            </>

                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                )}
                              </Menu>
                            )}

                          </td>
                        </tr>
                        <ModalIncident showModal={openModalIncident} hideModal={hideModal} id={sellerOrder.id} userId={userId} sellerId={sellerOrder.sellerId} orderLine={orderLine} orderNumber={orderNumber} lang={lang} />
                        <IncidentDetail showIncident={openDetailIncident} hideIncident={hideIncident} orderLine={orderLine} orderNumber={orderNumber} lang={lang} />
                      </>
                    )
                  })
                }
                <tr className="py-2">
                  <td className='md:p-3 md:h-8 space-x-1 text-xxm sm:text-xs'>
                    <span>Livraison:</span><span>{sellerOrder.delivery.deliveryModeId}</span>
                  </td>
                  <td className='md:p-3'><span></span></td>
                  <td className='md:p-3 text-orange-crossed text-xxm sm:text-xs'>{((sellerOrder.delivery.price) * 100 / 100).toFixed(2)} €</td>
                </tr>
                <tr>
                  <td className='md:p-3 flex content-center grid grid-flow-col md:text-sm text-s'> Total</td>
                  <td className='md:p-3'><span></span></td>
                  <td className='md:p-3 text-orange-crossed md:text-sm sm:text-s text-xs'>{((totalPrice + Number(sellerOrder.delivery.price)) * 100 / 100).toFixed(2)} €</td>
                </tr>
              </tbody>
            </table>
            <div className='pt-6 px-4 flex justify-center w-full text-grey-darkest'>
              {(sellerOrder.delivery.trackingUrl !== "" && sellerOrder.orderStatus === "WAITING_DELIVERY") ?
                <a class="bg-white text-gray-800 font-normal py-2 px-6 border border-gray-200 shadow hover:bg-orange-darkest hover:text-white"
                  rel="noopener noreferrer"
                  href={sellerOrder.delivery.trackingUrl}
                  target="_blank"
                >
                  Suivi
                </a>
                :
                <div className="text-grey">
                  <span>Suivi de livraison indisponible</span>
                </div>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  );

};

export default SellerOrderDetails;
import React, { useState, useRef, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from "react-styles-hook";
import { Formik, Field, Form } from 'formik';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { capitalizeFirst } from '../../utils/format';
import getStyles from "./styles";
import { incidentLaunched } from '../../state/reducer';


export const ModalIncident = ({ showModal, hideModal, id, userId, sellerId, orderLine, orderNumber, lang }) => {

  const classes = useStyles(getStyles());

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const subjectList = [
    "Produit",
    "Livraison",
    "Facturation",
    "Autre",
  ];

  const [selected, setSelected] = useState(subjectList[0]);

  const [send, setSend] = useState(false);

  const dispatch = useDispatch();
  const { incidentLoading, incidentSuccess, incidentError } = useSelector((state) => (
    {
      incidentLoading: state.getIn(['root', 'incidentLoading']),
      incidentSuccess: state.getIn(['root', 'incidentSuccess']),
      incidentError: state.getIn(['root', 'incidentError'])
    }
  ));

  // auto close modal or delete confirmation message after 3s ONLY if form is submitted
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return null;
    }
    if (send && incidentSuccess) {
      let timer;
      timer = setTimeout(() => hideModal(), 4500);
      return () => { clearTimeout(timer); };
    }
    return null;
  }, [send, incidentSuccess]);


  const initialValues = {
    id: id,
    sellerId: sellerId,
    userId: userId,
    orderLines:
    {
      offerId: orderLine.offerId,
      incident: [
        {
          hasIncident: true,
          amountWithVAT: orderLine.incident[0].amountWithVAT,
          createdDate: new Date().toISOString(),
          status: "NEW",
          subject: selected,
          message: ''
        }
      ]
    }
  };

  const onSubmit = (values) => {
    dispatch(incidentLaunched(values));
    setSend(true);
  };

  return (
    showModal
    && (
      <div
        role="button"
        className="overflow-y-auto cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
        onKeyPress={hideModal}
        tabIndex="0"
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          // don't close modal if anything inside modal content is clicked or any key is pressed
          onClick={(e) => { e.stopPropagation(); }}
          onKeyPress={(e) => { e.stopPropagation(); }}
        >
          {
            (!send || incidentLoading || (!incidentLoading && incidentError))
            && (
              <div className="w-3/4 md:w-full my-2">
                <div className="cursor-none">
                  <p className="block text-xxms font-medium text-gray-700">
                    Commande
                  </p>
                  <div className="mt-1">
                    <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                      {orderNumber}
                    </div>
                  </div>
                </div>
                <div className="mt-2 mb-4 cursor-none">
                  <p className="block text-xxms font-medium text-gray-700">
                    Article
                  </p>
                  <div className="mt-1">
                    <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                      {JSON.parse(orderLine.product) ? JSON.parse(orderLine.product).fr : "Nom article"}
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={initialValues}
                  // validationSchema={validationSchema}
                  validate={(values) => {
                    const errors = {};
                    // For validate message field
                    if (values.orderLines.incident[0].message.length < 5) {
                      errors.message = "Message requis";
                    } else if (values.orderLines.incident[0].message.length > 500) {
                      errors.message = "500 caractères maximum";
                    } else if (values.orderLines.incident[0].message === "") {
                      errors.message = "Message requis";
                    }
                    return errors;
                  }}
                  onSubmit={(values) => { onSubmit(values) }}
                >
                  {({ errors, touched, values, handleChange }) => (
                    <Form>
                      <div>
                        <Listbox
                          id="orderLines.incident[0].subject"
                          name="orderLines.incident[0].subject"
                          value={values.orderLines.incident[0].subject}
                          onChange={(e) => {
                            setSelected(e);
                            handleChange(e);
                          }}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-xxms font-medium text-gray-700">Sujet de l'incident</Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button className={`relative w-full bg-white border rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default ${(subjectList.length > 1) ? "focus:outline-none focus:ring-1 focus:ring-orange-dark focus:border-orange-dark" : ""} `}>
                                  <span className="block truncate text-xs">{capitalizeFirst(selected)}</span>
                                  {(subjectList.length > 1)
                                    && (
                                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </span>
                                    )}
                                </Listbox.Button>
                                {
                                  (subjectList.length > 1)
                                  && (
                                    <Transition
                                      show={open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                        {subjectList.map((subject) => (
                                          <Listbox.Option
                                            key={subject}
                                            className={({ active }) => classNames(
                                              active ? 'text-white bg-orange-chocolate' : 'text-gray-900',
                                              'text-xs cursor-default select-none relative py-2 pl-8 pr-4'
                                            )}
                                            value={subject}
                                          >
                                            {({ newSelected, active }) => (
                                              <>
                                                <span className={classNames(newSelected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                  {capitalizeFirst(subject)}
                                                </span>

                                                {newSelected ? (
                                                  <span
                                                    className={classNames(
                                                      active ? 'text-white' : 'text-indigo-600',
                                                      'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                    )}
                                                  >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  )
                                }
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                      <div className="mt-4">
                        <label htmlFor="message" className="block text-xxms font-medium text-gray-700">
                          Message (500 caractères max)
                        </label>
                        <div className="mt-1">
                          <Field
                            id="orderLines.incident[0].message"
                            name="orderLines.incident[0].message"
                            component="textarea"
                            rows={4}
                            value={values.orderLines.incident[0].message}
                            placeholder="Message"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            className="py-3 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border rounded-md"
                          />
                          {errors.message && (
                            <p className="px-2 text-red text-center md:text-left">{errors.message}</p>
                          )}
                        </div>
                        {(!send) && (
                          <div className="mt-2 text-xs text-grey-dark">
                            En appuyant sur "Envoyer", votre message sera transmis au vendeur afin d'étudier votre demande dans les plus brefs délais.
                          </div>
                        )}
                        {(!incidentLoading && incidentError) && (
                          <div className="mt-2 flex justify-center text-red">
                            Une erreur est survenue lors de l'envoi, veuillez réessayer.
                          </div>
                        )}
                        <div className="mt-6 flex justify-center">
                          <button
                            type="submit"
                            className="w-full md:w-52 px-3 py-1.5 border border-transparent rounded-md shadow-sm text-xxms font-medium text-white bg-orange-dark hover:bg-orange-chocolate focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-dark"
                          >
                            {incidentLoading ? "Envoi..." : "Envoyer"}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div className="mt-2 flex justify-center">
                  <button
                    type="button"
                    onClick={() => hideModal()}
                    className=" w-full md:w-52 px-3 py-1.5 border border-grey-medium rounded-md shadow-sm text-xxms font-medium text-grey bg-white capitalize"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            )
          }
          {
            (send && !incidentLoading && incidentSuccess)
            && (
              <div className="text-center">
                {capitalizeFirst("votre message a bien été envoyé")}
              </div>
            )
          }
        </div>
      </div>
    )
  );
};

export default ModalIncident;
import React from 'react';
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";


export const IncidentDetail = ({ showIncident, hideIncident, orderLine, orderNumber, lang }) => {

  const classes = useStyles(getStyles());

  const formatDate = (date, locale) => {
    return new Date(date).toLocaleDateString(locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    })
  };

  const translateStatut = (status) => {
    switch (status) {
      case "NEW":
        return "En cours";
      case "WAITING_FOR_REFUND":
        return "En attente de remboursement";
      case "REFUNDED":
        return "Cloturé: Remboursement effectué";
      case "CLOSED":
        return "Cloturé par le vendeur";
      default:
        return "Aucun statut disponible";
    }
  };

  return (
    showIncident
    && (
      <div
        role="button"
        className="overflow-y-auto cursor-auto absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center"
        style={classes.modalBackdrop}
        // close modal if anything outside modal content is clicked
        onClick={hideIncident}
        onKeyPress={hideIncident}
        tabIndex="0"
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-11/12 md:w-3/4 lg:w-4/6 xl:w-1/2 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8"
          // don't close modal if anything inside modal content is clicked or any key is pressed
          onClick={(e) => { e.stopPropagation(); }}
          onKeyPress={(e) => { e.stopPropagation(); }}
        >
          <div className="w-3/4 md:w-full my-2">
            <div className="cursor-none">
              <p className="block text-xxms font-medium text-gray-700">
                Incident ouvert le
              </p>
              <div className="mt-1">
                <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                  {formatDate(orderLine.incident[0].createdDate, lang)}
                </div>
              </div>
            </div>
            <div className=" mt-2 mb-4 cursor-none">
              <p className="block text-xxms font-medium text-gray-700">
                Statut de l'incident
              </p>
              <div className="mt-1">
                <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                  {translateStatut(orderLine.incident[0].status)}
                </div>
              </div>
            </div>
            {(orderLine.incident[0].status === "REFUNDED" && orderLine.incident[0].amountWithVAT !== 0) && (
              <div className="mt-2 mb-4 cursor-none">
                <p className="block text-xxms font-medium text-gray-700">
                  Montant remboursé
                </p>
                <div className="mt-1">
                  <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                    {(orderLine.incident[0].amountWithVAT * 100 / 100).toFixed(2)}€
                  </div>
                </div>
              </div>
            )}
            <div className="mt-2 mb-4 cursor-none">
              <p className="block text-xxms font-medium text-gray-700">
                Commande
              </p>
              <div className="mt-1">
                <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                  {orderNumber}
                </div>
              </div>
            </div>
            <div className="mt-2 mb-4 cursor-none">
              <p className="block text-xxms font-medium text-gray-700">
                Article
              </p>
              <div className="mt-1">
                <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                  {JSON.parse(orderLine.product) ? JSON.parse(orderLine.product).fr : "Nom article"}
                </div>
              </div>
            </div>
            <div className="mt-2 mb-4 cursor-none">
              <p className="block text-xxms font-medium text-gray-700">
                Votre message
              </p>
              <div className="mt-1">
                <div className="py-1 px-4 block w-full shadow-sm focus:ring-orange-dark focus:border-orange-dark border border-grey-medium rounded-md">
                  {orderLine.incident[0].message}
                </div>
              </div>
            </div>
            <div className="mt-2 flex justify-center">
              <button
                type="button"
                onClick={() => hideIncident()}
                className=" w-full md:w-52 px-3 py-1.5 border border-grey-medium rounded-md shadow-sm text-xxms font-medium text-grey bg-white capitalize hover:bg-orange-chocolate hover:text-white"
              >
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default IncidentDetail;
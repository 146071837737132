import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChevronUpIcon } from "@heroicons/react/solid";

import { searchSellerOrdersLaunched } from '../../state/reducer';
import SellerOrderDetails from './SellerOrderDetails';

const OrderInfo = ({ order, handleClick, lang }) => {

  const dispatch = useDispatch();

  const formatDate = (date, locale) => {
    return new Date(date).toLocaleDateString(locale, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    })
  }

  const { searchSellerOrdersLoading, searchSellerOrdersFailure, sellerOrders, incidentLoading, incidentSuccess } = useSelector((state) => ({
    searchSellerOrdersLoading: state.getIn(['root', 'searchSellerOrdersLoading']),
    searchSellerOrdersFailure: state.getIn(['root', 'searchSellerOrdersFailure']),
    sellerOrders: state.getIn(['root', 'sellerOrders']),
    incidentLoading: state.getIn(['root', 'incidentLoading']),
    incidentSuccess: state.getIn(['root', 'incidentSuccess']),
  }))

  const showDetails = (order) => {
    handleClick(order.id);
    dispatch(searchSellerOrdersLaunched({ customerOrderId: order.id }))
  }

  useEffect(() => {
    if (!incidentLoading && incidentSuccess) {
      handleClick(order.id);
      dispatch(searchSellerOrdersLaunched({ customerOrderId: order.id }))
    }
  }, [incidentLoading, incidentSuccess])

  return (
    <React.Fragment key={order.id}>
      <button
        className={`w-full px-4 text-left text-sm font-medium focus:outline-none focus-visible:none ${(order.isOpen) ? 'border-t border-l border-r border-grey-light py-0 pt-4' : 'py-6'}`}
        onClick={() => showDetails(order)} // get details about sellerOrder
        aria-expanded={order.isOpen}
        {...(order.isOpen && { "aria-controls": order.id })}
      >
        <div className="flex items-center justify-between">
          <p className="text-s md:text-sm font-medium text-indigo-600 truncate">N° {order.orderNumber}</p>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="text-xs md:text-s space-x-1">
              <span>Passée le</span>
              <time dateTime={order.createdDate}>{formatDate(order.createdDate, lang)}</time>
            </p>
          </div>
          <div className="mt-2 flex items-center justify-end sm:mt-0">
            <p className="text-sm">
              Détails
            </p>
            <ChevronUpIcon
              className={`${order.isOpen ? "rotate-180 transform" : ""
                } h-5 w-5 text-purple-500`}
            />
          </div>
        </div>
      </button>
      {(order.isOpen && sellerOrders && !searchSellerOrdersLoading) && (
        <div className="px-4 sm:px-8 pb-2 space-y-4 md:space-y-6 xl:space-y-8 shadow-xl border-b border-l border-r border-grey-light">
          <div class="flex flex-col justify-start items-start w-full divide-y divide-grey-light">
            {
              sellerOrders.map((sellerOrder) => {
                return (
                  <SellerOrderDetails
                    key={sellerOrder.id}
                    sellerOrder={sellerOrder}
                    userId={order.userId}
                    orderNumber={order.orderNumber}
                    delivery={order.delivery}
                    relayPoint={order.relayPointAddress}
                    billing={order.billingAddress}
                    lang={lang} />
                )
              })
            }
          </div>
        </div>
      )}
      {((order.isOpen && searchSellerOrdersLoading && !sellerOrders) || (order.isOpen && searchSellerOrdersLoading)) && (
        <div className="flex justify-center py-10 shadow-xl border-b border-l border-r border-grey-light">
          <p>Chargement...</p>
        </div>
      )}
      {(order.isOpen && searchSellerOrdersFailure) && (
        <div className="flex justify-center py-10">
          <p>Une erreur est survenue lors du chargement veuillez rafraichir la page.</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default OrderInfo